import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline } from '@mui/material';
import { ThemeContextProvider } from './ThemeContext';
import { HashRouter as Router, Routes, Route } from 'react-router-dom'; // Hinzugefügt
import Login from "./main/Login";
import UserDashboard from './dashboards/UserDashboard';
import AdminDashboard from './dashboards/AdminDashboard';
import ErrorPage from './errorPages/LoginFirstError'
// Importiere den Service Worker
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <ThemeContextProvider>
            <CssBaseline />
            {/* Router um die gesamte Anwendung */}
            <Router>
                <Routes>
                    {/* Definiere die Route für das Login */}
                    <Route path="/" element={<Login />} />
                    {/* Definiere die Route für das UserDashboard */}
                    <Route path="/user-dashboard" element={<UserDashboard />} />
                    {/* Definiere die Route für das AdminDashboard */}
                    <Route path="/admin-dashboard" element={<AdminDashboard />} />
                    <Route path="/loginFirst" element={<ErrorPage />} />
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </Router>
        </ThemeContextProvider>
    </React.StrictMode>
);

// Registriere den Service Worker hier
serviceWorkerRegistration.register();