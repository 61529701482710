import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Container } from '@mui/material';
import AdminDashboardHeader from "./headers/AdminDashboardHeader";
import { useNavigate } from 'react-router-dom';  // useNavigate importieren

const AdminDashboard = () => {
    const [mode, setMode] = useState('view');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [message, setMessage] = useState('');
    const [resetUsername, setResetUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [token, setToken] = useState(localStorage.getItem('token')); // Token von localStorage holen
    const [user, setUser] = useState(null); // Zustand für den eingeloggten Benutzer

    const navigate = useNavigate();  // Verwende useNavigate für Navigation

    // Funktion für den Login (wird bei Bedarf verwendet)
    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (!storedToken) {
            navigate('/loginFirst');  // Leitet zum Login um, wenn kein Token vorhanden ist
        } else {
            setToken(storedToken);  // Token setzen, falls vorhanden
        }
    }, [navigate]);

    // Funktion für den Logout
    const handleLogout = () => {
        // Setze den Benutzerzustand zurück und entferne das Token
        setUser(null);
        setToken(null);
        localStorage.removeItem('token'); // Entferne das Token aus dem localStorage

        // Sofortige Weiterleitung zur Login-Seite
        navigate('/', { replace: true });
    };

    // Token überprüfen und Benutzerdaten setzen
    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            setToken(storedToken);
        } else {
            setUser(null);
        }
    }, []);

    // Funktion zum Erstellen eines neuen Benutzers
    const createUser = async () => {
        try {
            const response = await axios.post('/api/admin/create-user', {
                username,
                email,
                password,
                role,
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } // Token senden
            });
            setMessage(`User ${response.data.username} created successfully!`);
        } catch (error) {
            setMessage(`Error creating user: ${error.response.data.error}`);
        }
    };

    // Funktion zum Zurücksetzen des Passworts
    const resetPassword = async () => {
        try {
            const response = await axios.put('/api/admin/reset-password', {
                username: resetUsername,
                newPassword,
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } // Token senden
            });
            setMessage(`Password for ${resetUsername} successfully updated!`);
        } catch (error) {
            setMessage(`Error resetting password: ${error.response.data.error}`);
        }
    };

    // Rückgabe der Admin-Dashboard-Komponenten
    return (
        <Container>
            {/* AdminDashboardHeader enthält den Logout-Button */}
            <AdminDashboardHeader mode={mode} setMode={setMode} onLogout={handleLogout} user={user} />

            <Typography variant="h4" gutterBottom>Admin Dashboard</Typography>

            <Typography variant="h6">Create User</Typography>
            <TextField label="Username" value={username} onChange={(e) => setUsername(e.target.value)} fullWidth />
            <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />
            <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} fullWidth />
            <TextField label="Role" value={role} onChange={(e) => setRole(e.target.value)} fullWidth />
            <Button variant="contained" color="primary" onClick={createUser}>Create User</Button>

            <Typography variant="h6" style={{ marginTop: '20px' }}>Reset User Password</Typography>
            <TextField label="Username" value={resetUsername} onChange={(e) => setResetUsername(e.target.value)} fullWidth />
            <TextField label="New Password" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} fullWidth />
            <Button variant="contained" color="secondary" onClick={resetPassword}>Reset Password</Button>

            {message && <Typography style={{ marginTop: '20px' }}>{message}</Typography>}
        </Container>
    );
};

export default AdminDashboard;