import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TextField,
    Typography,
    Menu,
    MenuItem,
    Button
} from '@mui/material';
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Save as SaveIcon,
    Add as AddIcon,
    MoreVert as MoreVertIcon
} from '@mui/icons-material';
import { addTrainingStatus, updateTrainingStatus, deleteTrainingStatus } from '../api/data';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('de-DE', options);
};

const TrainingStatus = ({ trainingStatus, student }) => {
    const [columns, setColumns] = useState([]);
    const [editMode, setEditMode] = useState(null);
    const [editedColumn, setEditedColumn] = useState({});
    const [newColumn, setNewColumn] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [showIcons, setShowIcons] = useState(false);

    useEffect(() => {
        setColumns(trainingStatus);
        setNewColumn({});
    }, [trainingStatus, student]);

    const handleEditClick = (index) => {
        setEditMode(index);
        setEditedColumn(columns[index]);
    };

    const handleSaveClick = async () => {
        if (!student || !student.id || editMode === null) {
            console.error('Student ID or editMode is missing');
            return;
        }

        const updatedStatus = {
            ...columns[editMode],
            ...editedColumn,
            spalte_id: columns[editMode].spalte_id
        };

        try {
            const response = await updateTrainingStatus(updatedStatus);
            setColumns((prevColumns) => {
                const newColumns = [...prevColumns];
                newColumns[editMode] = response;
                return newColumns;
            });
            setEditMode(null);
        } catch (error) {
            console.error('Error updating training status:', error);
        }
    };

    const handleDeleteClick = async (index) => {
        if (!student || !student.id) {
            console.error('Student ID is missing');
            return;
        }

        const spalte_id = columns[index].spalte_id;

        try {
            await deleteTrainingStatus(spalte_id);
            setColumns((prevColumns) => prevColumns.filter((_, colIndex) => colIndex !== index));
        } catch (error) {
            console.error('Error deleting training status:', error);
        }
    };

    const handleInputChange = (e, key) => {
        if (editMode !== null) {
            setEditedColumn({
                ...editedColumn,
                [key]: e.target.value
            });
        } else {
            setNewColumn({
                ...newColumn,
                [key]: e.target.value
            });
        }
    };

    const handleAddColumn = async () => {
        if (!student || !student.id) {
            console.error('Student ID is missing');
            return;
        }

        const newTrainingStatus = {
            id: student.id,
            ...newColumn
        };

        try {
            const addedColumn = await addTrainingStatus(newTrainingStatus);
            setColumns([...columns, addedColumn]);
            setNewColumn({});
        } catch (error) {
            console.error('Error adding new column:', error);
        }
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // Funktion zum Umschalten des Bearbeitungsmodus
    const handleEditOption = () => {
        if (showIcons) {
            setShowIcons(false);  // Bearbeitungsmodus beenden
            setEditMode(null); // Optionally, reset any editing mode
        } else {
            setShowIcons(true);  // Bearbeitungsmodus starten
        }
        setAnchorEl(null);
    };

    const handleGeneratePdf = () => {
        const doc = new jsPDF('p', 'mm', 'a4');

        // Titel des Dokuments
        doc.text(`Ausbildungsstand von ${student.vorname} ${student.name}`, 10, 10);

        // Tabelle erstellen
        const tableColumn = ["Ausbildungspunkt", ...columns.map((_, index) => `${index + 1}. Fahrstunde`)];
        const tableRows = [];

        rows.forEach(({ label, key }) => {
            const rowData = [label];
            columns.forEach(status => {
                // Datum im Format dd.mm.yyyy formatieren
                let cellValue = status[key];
                if (key === 'datum' && cellValue) {
                    const date = new Date(cellValue);
                    cellValue = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
                }
                rowData.push(cellValue || '');
            });
            tableRows.push(rowData);
        });

        // Tabelle mit Optionen für eine Seite und zentriertem Text
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 15, // Y-Position, um die Tabelle zu beginnen
            styles: {
                fontSize: 6,   // Sehr kleine Schriftgröße
                cellPadding: 0.5, // Minimaler Abstand in den Zellen
                halign: 'center', // Text zentriert
            },
            theme: 'grid', // Fügt Tabellenlinien hinzu
            headStyles: {
                fillColor: [0, 112, 192], // Blauer Hintergrund für den UserDashboardHeader
                textColor: [255, 255, 255] // Weißer Text für den UserDashboardHeader
            },
            margin: { top: 15, right: 5, bottom: 5, left: 5 }, // Minimalmargen
            tableWidth: 'auto',
            columnStyles: {
                0: { cellWidth: 35 }, // Erste Spalte angepasst
            },
            pageBreak: 'avoid', // Seitenumbruch vermeiden
        });

        // PDF speichern
        doc.save(`ausbildungsstand_${student.vorname.trim()}_${student.name.trim()}.pdf`);
    };

    const rows = [
        { label: 'Datum', key: 'datum' },
        { label: 'Sitz, Spiegel, Lenkradtechnik', key: 'sitz_spiegel_lenkradtechnik' },
        { label: 'Kupplung, Schleifpunkt, Anfahren, Anhalten', key: 'kupplung_schleifpunkt_anfahren_anhalten' },
        { label: 'Schalten 1-6, Gangwahl', key: 'schalten_1_6_gangwahl' },
        { label: 'Verkehrsbeobachtung auf Kreuzungen', key: 'verkehrsbeobachtung_auf_kreuzungen' },
        { label: 'Blicktechnik (1, 2, 3), Blinken', key: 'blicktechnik_1_2_3_blinken' },
        { label: 'Umsetzen ins Anfahren, Anhalten und Abbiegen', key: 'umsetzen_ins_anfahren_anhalten_und_abbiegen' },
        { label: 'Blicktechnische Regeln', key: 'blicktechnische_regeln' },
        { label: 'Blickführung beim Abbiegen', key: 'blickführung_beim_abbiegen' },
        { label: 'Auf freier Strecke, beim Engpass, Spurhalten', key: 'auf_freier_strecke_beim_engpass_spurhalten' },
        { label: 'Blinkerbetätigung', key: 'blinkerbetätigung' },
        { label: 'Überlandfahrt, Geschwindigkeitsgestaltung', key: 'Überlandfahrt_geschwindigkeitsgestaltung' },
        { label: 'Gangwahl, Kurventechnik, Blicktiefe', key: 'gangwahl_kurventechnik_blicktiefe' },
        { label: 'Fahrbahnbenutzung', key: 'fahrbahnbenutzung' },
        { label: 'Wo musst du dich bewegen', key: 'wo_musst_du_dich_bewegen' },
        { label: 'Einspuren (Abbiegen links, rechts)', key: 'einspuren_abbiegen_links_rechts' },
        { label: 'Bewegungsablauf beim Abbiegen', key: 'bewegungsablauf_beim_abbiegen' },
        { label: 'Fahrstreifenbenutzung', key: 'fahrstreifenbenutzung' },
        { label: 'Einspurstrecke, Radstreifen, Einbahnstrasse', key: 'einspurstrecke_radstreifen_einbahnstrasse' },
        { label: 'Bremsbereitschaft, Sichtpunkt', key: 'bremsbereitschaft_sichtpunkt' },
        { label: 'Verzweigungen befahren', key: 'verzweigungen_befahren' },
        { label: 'Stop / kein Vortritt / Rechtsvortritt', key: 'stop_kein_vortritt_rechtsvortritt' },
        { label: 'Kreiselbefahren lenken, SB, blinken', key: 'kreiselbefahren_lenken_sb_blinken' },
        { label: 'Überholen (Fahrende Fahrzeuge, Stehende Hindernisse)', key: 'Überholen_fahrende_fahrzeuge_stehende_hindernisse' },
        { label: 'Verhalten bei Lichtsignalen / Baustellen', key: 'verhalten_bei_lichtsignalen_baustellen' },
        { label: 'Verhalten bei Bahnübergängen', key: 'verhalten_bei_bahnübergängen' },
        { label: 'Bergsichern (Steigung / Gefälle)', key: 'bergsichern_steigung_gefälle' },
        { label: 'Berganfahren mit Handbremse', key: 'berganfahren_mit_handbremse' },
        { label: 'Rückwärtsfahren', key: 'rückwärtsfahren' },
        { label: 'Wenden (Vorwärts zwischen Begrenzungen, 3-Punkt)', key: 'wenden_vorwärts_zwischen_begrenzungen_3_punkt' },
        { label: 'Wenden (Rückwärts in Ausweichstelle)', key: 'wenden_rückwärts_in_ausweichstelle' },
        { label: 'Parkieren rechtwinklig vorwärts', key: 'parkieren_recktwinklig_vorwärts' },
        { label: 'Parkieren rechtwinklig rückwärts', key: 'parkieren_rechtwinklig_rückwärts' },
        { label: 'Parkieren schräg vorwärts', key: 'parkieren_schräg_vorwärts' },
        { label: 'Parkieren seitwärts rechts', key: 'parkieren_seitwärts_rechts' },
        { label: 'Parkieren seitwärts links', key: 'parkieren_seitwärts_links' },
        { label: 'Korrekturen', key: 'korrekturen' },
        { label: 'Geschwindigkeitsgestaltung', key: 'geschwindigkeitsgestalltung' },
        { label: 'Kreuzen', key: 'kreuzen' },
        { label: 'Strassenbahn / öffentliche Verkehrsmittel', key: 'strassenbahn_öffentliche_verkehrsmittel' },
        { label: 'Kolonnenfahren', key: 'kolonnenfahren' },
        { label: 'Einfädeln / Lückenbenutzung', key: 'einfädeln_lückenbenutzung' },
        { label: 'Beachten von Signalen / Markierungen', key: 'beachten_von_signalen_markierungen' },
        { label: 'Abstand', key: 'abstand' },
        { label: 'Verhalten bei Fussgängern / Fahrrädern', key: 'verhalten_bei_fussgängern_fahrrädern' },
        { label: 'Verkehrsregelung', key: 'verkehrsregelung' },
        { label: 'Schwierige Partner', key: 'schwierige_partner' },
        { label: 'Wegweisefahrten', key: 'wegweisefahrten' },
        { label: 'Voraussicht', key: 'voraussicht' },
        { label: 'Autobahn (Ein- und Ausfahrt)', key: 'autobahn_ein_und_ausfahrt' },
        { label: 'Autobahn (Spurwechsel)', key: 'autobahn_spurwechsel' },
        { label: 'Autobahn (Geschwindigkeit und Mithalten)', key: 'autobahn_geschwindigkeit_und_mithalten' },
        { label: 'Einspuren bei Doppelkopf', key: 'einspuren_bei_doppelkopf' },
        { label: 'Einspuren beim Abbiegen', key: 'einspuren_beim_abbiegen' },
        { label: 'Spurwechsel', key: 'spurwechsel' },
        { label: 'Fahren nach Merkpunkten', key: 'fahren_nach_merkpunkten' },
        { label: 'Wegweisen / Zielfahrten', key: 'wegweisen_zielfahrten' },
        { label: 'Gefahrentraining / Prüfungsfahrten', key: 'gefahrentraining_prüfungsfahrten' },
        { label: 'Notbremsung', key: 'notbremsung' }
    ];

    return (
        <TableContainer component={Paper} >
            <div style={{ display: 'flex', position: 'relative'}}>
                <h3 style={{marginLeft: "20px"}}>Ausbildungsstand von {`${student.vorname} ${student.name}`}</h3>
                <IconButton onClick={handleMenuClick} color="primary">
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleEditOption}>
                        {showIcons ? 'Bearbeitung beenden' : 'Ausbildungsstand bearbeiten'}
                    </MenuItem>
                </Menu>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGeneratePdf}
                    style={{
                        position: "absolute",
                        right: '15px', // Abstand vom rechten Rand
                        maxHeight: '40px',
                        marginTop: '15px'
                    }}
                >
                    Daten in PDF exportieren
                </Button>
            </div>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{ maxWidth: "100%", overflowX: "auto",
                                paddingTop: '35px',
                            }}
                        ><b>Ausbildungspunkt</b></TableCell>
                        {showIcons && (
                            <TableCell
                                style={{ maxWidth: "100%", overflowX: "auto",
                                    verticalAlign: 'middle',
                                    textAlign: 'center',
                                    padding: '0',
                                }}
                            >
                                <IconButton
                                    color="primary"
                                    onClick={handleAddColumn}
                                    style={{ maxWidth: "100%", overflowX: "auto",
                                        marginTop: '25px',
                                        borderRadius: '40%',
                                        padding: '0.5px 0.5px'
                                    }}
                                >
                                    <AddIcon/>
                                </IconButton>
                            </TableCell>
                        )}
                        {columns.slice().reverse().map((_, index) => (
                            <TableCell key={index}>
                                <b>{columns.length - index}. Fahrstunde</b>
                                <div style={{ maxWidth: "100%", overflowX: "auto", display: 'flex', alignItems: 'center'}}>
                                    {showIcons && (
                                        <>
                                            <IconButton
                                                color="primary"
                                                onClick={() =>
                                                    editMode === index ? handleSaveClick() : handleEditClick(index)
                                                }
                                                style={{ maxWidth: "100%", overflowX: "auto", marginLeft: '8px', padding: '4px 8px'}}
                                            >
                                                {editMode === index ? <SaveIcon/> : <EditIcon/>}
                                            </IconButton>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleDeleteClick(index)}
                                                style={{ maxWidth: "100%", overflowX: "auto", marginLeft: '8px', padding: '4px 8px'}}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </>
                                    )}
                                </div>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(({label, key}, rowIndex) => (
                        <TableRow key={rowIndex}>
                            <TableCell>{label}</TableCell>
                            {showIcons && (
                                <TableCell>
                                    {key === 'datum' ? (
                                        <TextField
                                            type="date"
                                            variant="outlined"
                                            size="small"
                                            value={newColumn[key] || ''}
                                            onChange={(e) => handleInputChange(e, key)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    ) : (
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            value={newColumn[key] || ''}
                                            onChange={(e) => handleInputChange(e, key)}
                                        />
                                    )}
                                </TableCell>
                            )}
                            {columns.map((status, colIndex) => {
                                let value = status[key];

                                if (key === 'datum' && value && editMode !== colIndex) {
                                    value = formatDate(value);
                                }

                                return (
                                    <TableCell key={colIndex}
                                               style={{ maxWidth: "100%", overflowX: "auto",
                                                   verticalAlign: 'middle',
                                                   textAlign: 'center',
                                                   padding: '0',
                                               }}
                                    >
                                        {editMode === colIndex ? (
                                            key === 'datum' ? (
                                                <TextField
                                                    type="date"
                                                    variant="outlined"
                                                    size="small"
                                                    value={editedColumn[key] ? new Date(editedColumn[key]).toISOString().split('T')[0] : ''}
                                                    onChange={(e) => handleInputChange(e, key)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            ) : (
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    value={editedColumn[key] || ''}
                                                    onChange={(e) => handleInputChange(e, key)}
                                                />
                                            )
                                        ) : (
                                            value || ''
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TrainingStatus;