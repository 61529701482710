import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, TextField, IconButton, Grid, Menu, MenuItem } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Save as SaveIcon, Add as AddIcon, MoreVert as MoreVertIcon, Close as CloseIcon } from '@mui/icons-material';
import { updateLesson, deleteLesson, addLesson } from '../api/data'; // importiere addLesson

const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('de-DE', options);
};

const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    const options = { hour: '2-digit', minute: '2-digit' };
    return date.toLocaleTimeString('de-DE', options);
};

const LessonList = ({ lessons = [], student, onUpdateLesson, onAddLesson, onDeleteLesson }) => {
    const [editMode, setEditMode] = useState(null);
    const [editedLesson, setEditedLesson] = useState({});
    const [isAdding, setIsAdding] = useState(false); // Zustand für das Hinzufügen
    const [showIcons, setShowIcons] = useState(false); // Zustand für die Sichtbarkeit der Bearbeitungs-Icons
    const [anchorEl, setAnchorEl] = useState(null); // Für das Menü
    const [datum, setDatum] = useState('');
    const [fahrstundendauer, setFahrstundendauer] = useState('');
    const [bemerkung, setBemerkung] = useState('');
    const [uhrzeit, setUhrzeit] = useState('');
    const [betrag, setBetrag] = useState('');
    const [bezahlungsart, setBezahlungsart] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // Blockiert mehrfaches Hinzufügen

    useEffect(() => {
        if (editMode !== null) {
            const lesson = lessons.find((lesson) => lesson.fahrstunden_id === editMode);
            if (lesson) {
                setEditedLesson({
                    ...lesson,
                    datum: new Date(lesson.datum).toISOString().split('T')[0],
                    uhrzeit: lesson.uhrzeit
                });
            }
        }
    }, [editMode, lessons]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleToggleEditMode = () => {
        setShowIcons(!showIcons);
        setIsAdding(false);
        setAnchorEl(null);
    };

    // Beim Bearbeiten einer Fahrstunde sicherstellen, dass Datum und Uhrzeit im richtigen Format sind
    const handleSaveClick = async () => {
        const localDateTime = `${editedLesson.datum} ${editedLesson.uhrzeit}`; // Datum und Uhrzeit korrekt kombinieren

        const updatedLesson = {
            ...lessons[editMode],
            ...editedLesson,
            datum: localDateTime, // Verwende das kombinierte Datum und Uhrzeit direkt
            fahrstunden_id: lessons[editMode].fahrstunden_id,
        };

        try {
            const response = await updateLesson(updatedLesson);
            onUpdateLesson(response);
            setEditMode(null);
        } catch (error) {
            console.error('Error updating lesson:', error);
        }
    };

    const handleDeleteClick = async (index) => {
        if (!student || !student.id) {
            console.error('Student ID is missing');
            return;
        }

        const fahrstunden_id = lessons[index]?.fahrstunden_id;

        if (!fahrstunden_id) {
            console.error('fahrstunden_id is missing or invalid');
            return;
        }

        try {
            await deleteLesson(fahrstunden_id);
            onDeleteLesson(fahrstunden_id);
        } catch (error) {
            console.error('Error deleting lesson:', error);
        }
    };

    const handleInputChange = (e, key) => {
        setEditedLesson({
            ...editedLesson,
            [key]: e.target.value,
        });
    };

    const handleAddLessonClick = () => {
        setIsAdding(true);
        setIsSubmitting(false); // Sicherstellen, dass das Absenden freigegeben ist
        setEditedLesson({
            datum: '',
            uhrzeit: '',
            fahrstundendauer: '',
            betrag: '',
            bezahlungsart: '',
            bemerkungen: ''
        });
    };

    const handleSubmit = async () => {
        if (isSubmitting) return;

        setIsSubmitting(true);

        const newLesson = {
            id: student.id,
            datum: `${datum} ${uhrzeit}`, // Datum und Uhrzeit kombiniert
            fahrstundendauer: parseInt(fahrstundendauer.trim(), 10),
            bemerkung: bemerkung.trim(),
            uhrzeit: uhrzeit.trim(),
            betrag: parseInt(betrag.trim(), 10),
            bezahlungsart: bezahlungsart.trim(),
        };

        try {
            const addedLesson = await addLesson(newLesson);
            onAddLesson(addedLesson);

            // Setze Zustand zurück und schließe Formular nach erfolgreichem Hinzufügen
            setIsAdding(false);
            setDatum('');
            setFahrstundendauer('');
            setBemerkung('');
            setUhrzeit('');
            setBetrag('');
            setBezahlungsart('');
        } catch (error) {
            console.error('Error adding lesson:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleEditClick = (index) => {
        setEditMode(index);
        const lesson = lessons[index];

        // Hier wird das Datum direkt im lokalen Format extrahiert, ohne UTC-Konvertierung
        const lessonDate = new Date(lesson.datum);

        // Hole das Datum im Format YYYY-MM-DD aus dem lokalen Datum
        const year = lessonDate.getFullYear();
        const month = String(lessonDate.getMonth() + 1).padStart(2, '0'); // Monate sind 0-indexiert, daher +1
        const day = String(lessonDate.getDate()).padStart(2, '0'); // Tag

        const formattedDate = `${year}-${month}-${day}`; // Datum im Format YYYY-MM-DD

        setEditedLesson({
            ...lesson,
            datum: formattedDate, // Verwende das lokal formatierte Datum
            uhrzeit: lesson.uhrzeit // Sollte bereits im HH:MM-Format vorliegen
        });
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <h3>Fahrstundenliste von {`${student.vorname} ${student.name}`}</h3>
                </Grid>
                <Grid item>
                    <IconButton color="primary" onClick={handleMenuClick}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleToggleEditMode}>
                            {showIcons ? 'Bearbeiten beenden' : 'Bearbeiten starten'}
                        </MenuItem>
                    </Menu>
                </Grid>
            </Grid>

            {/* Formular zum Hinzufügen einer neuen Fahrstunde, nur sichtbar, wenn Bearbeitungsmodus aktiv ist */}
            {showIcons && isAdding && (
                <ListItem>
                    <Grid container spacing={2} style={{ maxWidth: "100%" }}>
                        <Grid item xs={6}>
                            <TextField
                                label="Datum"
                                type="date"
                                value={datum}
                                onChange={(e) => setDatum(e.target.value)}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Uhrzeit"
                                type="time"
                                value={uhrzeit}
                                onChange={(e) => setUhrzeit(e.target.value)}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Dauer"
                                type="number"
                                value={fahrstundendauer}
                                onChange={(e) => setFahrstundendauer(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Betrag"
                                type="number"
                                value={betrag}
                                onChange={(e) => setBetrag(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Bezahlungsart"
                                value={bezahlungsart}
                                onChange={(e) => setBezahlungsart(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Bemerkung"
                                value={bemerkung}
                                onChange={(e) => setBemerkung(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end" style={{ maxWidth: "100%", marginTop: '10px' }}>
                        <IconButton color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                            <SaveIcon />
                        </IconButton>
                        <IconButton color="secondary" onClick={() => setIsAdding(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </ListItem>
            )}

            {showIcons && !isAdding && (
                <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                    <IconButton color="primary" onClick={handleAddLessonClick}>
                        <AddIcon />
                    </IconButton>
                </Grid>
            )}

            <List>
                {lessons.map((lesson, index) => (
                    <ListItem key={lesson.fahrstunden_id}>
                        {editMode === index ? (
                            <>
                                <Grid container spacing={2} style={{ maxWidth: "100%" }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Datum"
                                            type="date"
                                            value={editedLesson.datum || ''}
                                            onChange={(e) => handleInputChange(e, 'datum')}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Uhrzeit"
                                            type="time"
                                            value={editedLesson.uhrzeit || ''}
                                            onChange={(e) => handleInputChange(e, 'uhrzeit')}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Dauer"
                                            type="number"
                                            value={editedLesson.fahrstundendauer || ''}
                                            onChange={(e) => handleInputChange(e, 'fahrstundendauer')}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Betrag"
                                            type="number"
                                            value={editedLesson.betrag || ''}
                                            onChange={(e) => handleInputChange(e, 'betrag')}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Bezahlungsart"
                                            value={editedLesson.bezahlungsart || ''}
                                            onChange={(e) => handleInputChange(e, 'bezahlungsart')}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Bemerkung"
                                            value={editedLesson.bemerkungen || ''}
                                            onChange={(e) => handleInputChange(e, 'bemerkungen')}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="flex-end" style={{ maxWidth: "100%", marginTop: '10px' }}>
                                    <IconButton color="primary" onClick={handleSaveClick}>
                                        <SaveIcon />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={() => setEditMode(null)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <ListItemText
                                    primary={`Datum: ${formatDate(lesson.datum)} - Uhrzeit: ${formatTime(lesson.uhrzeit)}`}
                                    secondary={`Dauer: ${lesson.fahrstundendauer} Stunde(n) | Betrag: ${lesson.betrag} CHF | Bezahlungsart: ${lesson.bezahlungsart} | Bemerkung: ${lesson.bemerkungen}`}
                                />
                                {showIcons && (
                                    <>
                                        <IconButton color="primary" onClick={() => handleEditClick(index)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton color="secondary" onClick={() => handleDeleteClick(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                                )}
                            </>
                        )}
                    </ListItem>
                ))}
            </List>

        </>
    );
};

export default LessonList;
