import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // FullCalendar-Komponente importieren
import dayGridPlugin from '@fullcalendar/daygrid'; // Monatliche Kalenderansicht
import timeGridPlugin from '@fullcalendar/timegrid'; // Wochen- und Tagesansicht
import interactionPlugin from '@fullcalendar/interaction'; // Für Interaktion (z.B. Klicken auf Datum)
import moment from 'moment-timezone'; // Importiere moment-timezone für Zeitzonenunterstützung
import { getAllLessons } from '../api/data';

const MyCalendar = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchFahrstunden = async () => {
            try {
                const fahrstundenData = await getAllLessons();
                console.log(fahrstundenData); // Protokolliere die empfangenen Daten

                // Fahrstunden-Daten in FullCalendar-Events umwandeln
                const calendarEvents = fahrstundenData.map((fahrstunde) => {
                    const { datum, uhrzeit, fahrstundendauer, bemerkungen, vorname, name } = fahrstunde;

                    // Verwende moment.js, um das Datum von UTC in die lokale Zeitzone (z.B. Europe/Berlin) zu konvertieren
                    const startDateUTC = moment.utc(`${datum} ${uhrzeit}`, 'YYYY-MM-DD HH:mm:ss');

                    console.log('StartDate Local:', startDateUTC.format());

                    // Füge die Fahrstundendauer zur Startzeit hinzu
                    const endDate = moment(startDateUTC).add(fahrstundendauer, 'hours').toDate();
                    console.log('EndDate:', endDate);

                    return {
                        title: `${vorname} ${name} - ${bemerkungen}`, // Titel: Name des Fahrschülers + Bemerkung
                        start: startDateUTC.toDate(), // Korrekt formatiertes Startdatum als JavaScript Date
                        end: endDate, // Enddatum basierend auf der Dauer
                    };
                });

                setEvents(calendarEvents); // Ereignisse setzen
            } catch (error) {
                console.error('Error fetching fahrstunden for calendar:', error);
            }
        };

        fetchFahrstunden();
    }, []);

    return (
        <div className="calendar-container">
            <FullCalendar
                className="full-height" // Kalender passt sich der verfügbaren Höhe an
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth" // Standardansicht: Monatsansicht
                events={events} // Ereignisse aus dem Zustand
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                locale="de" // Kalender auf Deutsch setzen
                buttonText={{
                    today: 'Heute',
                    month: 'Monat',
                    week: 'Woche',
                    day: 'Tag',
                }}
                allDayText="Ganztägig"
                slotLabelFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    meridiem: false, // Zeit im 24-Stunden-Format anzeigen
                }}
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    meridiem: false, // Zeit im 24-Stunden-Format anzeigen
                }}
                selectable={true} // Ermöglicht das Klicken auf Zeitfenster
                dateClick={(info) => alert(`Datum geklickt: ${info.dateStr}`)} // Beispiel-Interaktion
                height="auto" // Dynamische Höhe
                slotMinTime="08:00:00" // Sichtbarer Startzeitpunkt in der Tages-/Wochenansicht
                slotMaxTime="18:00:00" // Sichtbares Ende des Zeitrasters in der Tages-/Wochenansicht
                expandRows={true} // Zeilen werden gestreckt, um den gesamten Platz zu nutzen
            />
        </div>
    );
};

export default MyCalendar;

