import axios from 'axios';

const API_URL = 'https://ausbildungskarten.flow-drive.ch';

export const getStudents = async () => {
    try {
        const response = await axios.get(`${API_URL}/persoenlichedaten`);
        return response.data;
    } catch (error) {
        console.error('Error fetching students:', error);
        throw error;
    }
};

export const addStudent = async (student) => {
    try {
        const response = await axios.post(`${API_URL}/persoenlichedaten`, student);
        return response.data;
    } catch (error) {
        console.error('Error adding student:', error);
        throw error;
    }
};

// Neue Funktion zum Löschen eines Fahrschülers
export const deleteStudent = async (studentId) => {
    try {
        await axios.delete(`${API_URL}/persoenlichedaten/${studentId}`);
    } catch (error) {
        console.error(`Error deleting student with ID ${studentId}:`, error);
        throw error;
    }
};

export const getLessons = async (studentId) => {
    try {
        const response = await axios.get(`${API_URL}/fahrstunden/${studentId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching lessons for student ${studentId}:`, error);
        throw error;
    }
};

export const getAllLessons = async () => {
    try {
        const response = await axios.get(`${API_URL}/alleFahrstunden`);
        return response.data; // Fahrstunden-Daten werden zurückgegeben
    } catch (error) {
        console.error('Error fetching fahrstunden:', error);
        throw error;
    }
};

export const addLesson = async (lesson) => {
    try {
        const response = await axios.post(`${API_URL}/addFahrstunden`, lesson);
        return response.data;
    } catch (error) {
        console.error('Error adding lesson:', error);
        throw error;
    }
};

export const getTrainingStatus = async (studentId) => {
    try {
        const response = await axios.get(`${API_URL}/ausbildungsstand/${studentId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching training status:', error);
        throw error;
    }
};

export const addTrainingStatus = async (trainingStatus) => {
    try {
        const response = await axios.post(`${API_URL}/ausbildungsstand`, trainingStatus);
        return response.data;
    } catch (error) {
        console.error('Error adding training status:', error);
        throw error;
    }
};

export const updateTrainingStatus = async (updatedStatus) => {
    try {
        const response = await axios.put(`${API_URL}/ausbildungsstand/spalte/${updatedStatus.spalte_id}`, updatedStatus);
        return response.data;
    } catch (error) {
        console.error('Error updating training status:', error);
        throw error;
    }
};

export const deleteTrainingStatus = async (spalteId) => {
    try {
        await axios.delete(`${API_URL}/ausbildungsstand/spalte/${spalteId}`);
    } catch (error) {
        console.error('Error deleting training status:', error);
        throw error;
    }
};

export const updateLesson = async (cleanedLesson) => {
    try {
        const response = await axios.put(`${API_URL}/fahrstunden/${cleanedLesson.fahrstunden_id}`, cleanedLesson);
        return response.data;
    } catch (error) {
        console.error('Error updating lesson:', error);
        throw error;
    }
};

export const deleteLesson = async (fahrstunden_id) => {
    try {
        await axios.delete(`${API_URL}/fahrstunden/${fahrstunden_id}`);
    } catch (error) {
        console.error('Error deleting lesson:', error);
        throw error;
    }
};

export const registerUser = async (user) => {
    try {
        const response = await axios.post(`${API_URL}/register`, user);
        return response.data; // Möglicherweise gibt es eine Erfolgsmeldung zurück
    } catch (error) {
        console.error('Error registering user:', error);
        throw error;
    }
};

export const loginUser = async (credentials) => {
    try {
        const response = await axios.post(`${API_URL}/login`, credentials);
        const { token, user } = response.data;

        // Speichere das Token im LocalStorage
        localStorage.setItem('token', token);

        return { user, token };
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

export const refreshToken = async (token) => {
    try {
        const response = await axios.post(`${API_URL}/refresh-token`, { token });
        const { token: newToken } = response.data;

        // Speichere das neue Token im LocalStorage
        localStorage.setItem('token', newToken);

        return newToken;
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
    }
};