import React, { useState } from 'react';
import { TextField, List, ListItem, ListItemText, IconButton, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, MoreVert as MoreVertIcon, Close as CloseIcon } from '@mui/icons-material';
import { deleteStudent } from '../api/data';

const StudentList = ({ students, setStudents, onSelect }) => {
    const [search, setSearch] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    // Filtered list based on the search input
    const filteredStudents = students.filter(student =>
        `${student.vorname.toLowerCase()} ${student.name.toLowerCase()}`.includes(search.toLowerCase())
    );

    const handleMenuClick = (event, studentId) => {
        setAnchorEl(event.currentTarget);
        setSelectedStudentId(studentId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEditOption = () => {
        setEditMode(true);
        setAnchorEl(null);
    };

    const handleCancelEdit = () => {
        setEditMode(false);
        setSelectedStudentId(null);
    };

    const handleDeleteClick = () => {
        setDeleteDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteStudent(selectedStudentId);  // Fahrschüler löschen
            setDeleteDialogOpen(false);
            setSelectedStudentId(null);
            // Entferne den Schüler aus der Liste nach erfolgreichem Löschen
            setStudents(prevStudents => prevStudents.filter(student => student.id !== selectedStudentId));
        } catch (error) {
            console.error("Fehler beim Löschen des Fahrschülers:", error);
        }
    };

    const handleCancelDelete = () => {
        setDeleteDialogOpen(false);
    };

    return (
        <div style={{ maxWidth: '600px', margin: 'auto', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ display: 'flex', marginBottom: '30px', marginTop: '20px' }}>
                <TextField
                    label="Suche Fahrschüler"
                    variant="outlined"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    style={{ marginLeft: '20px', marginTop: "20px" }}
                />
            </div>
            <div style={{ flex: 1, overflowY: 'auto', maxHeight: '400px' }}>
                <List style={{ overflowY: 'auto', height: '100%' }}>
                    {filteredStudents.map(student => (
                        <ListItem key={student.id}>
                            <ListItemText
                                primary={`${student.vorname} ${student.name}`}
                                onClick={() => onSelect(student)}
                                style={{ cursor: 'pointer', flex: 1 }}
                            />
                            {!editMode || selectedStudentId !== student.id ? (
                                <IconButton color="primary" onClick={(e) => handleMenuClick(e, student.id)}>
                                    <MoreVertIcon />
                                </IconButton>
                            ) : (
                                <>
                                    <IconButton color="primary">
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={handleDeleteClick}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={handleCancelEdit}>
                                        <CloseIcon />
                                    </IconButton>
                                </>
                            )}
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl) && selectedStudentId === student.id}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleEditOption}>Fahrschüler bearbeiten</MenuItem>
                            </Menu>
                        </ListItem>
                    ))}
                </List>
            </div>

            {/* Bestätigungsdialog für das Löschen */}
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCancelDelete}
            >
                <DialogTitle>Fahrschüler löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bist du sicher, dass du diesen Fahrschüler löschen möchtest? Diese Aktion kann nicht rückgängig gemacht werden.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default StudentList;