import React, { useState, useEffect, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Container, Grid, Paper, CircularProgress, IconButton, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import StudentList from '../components/StudentList';
import LessonList from '../components/LessonList';
import AddStudent from '../components/AddStudent';
import TrainingStatus from "../components/TrainingStatus";
import UserDashboardHeader from './headers/UserDashboardHeader';
import MyCalendar from '../components/Calendar';

import {getStudents, getLessons, addStudent, addLesson, getTrainingStatus, deleteStudent} from '../api/data';
import { useNavigate } from 'react-router-dom';

const UserDashboard = () => {
    const [mode, setMode] = useState('view');
    const [students, setStudents] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [trainingStatus, setTrainingStatus] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [isLoadingStudents, setIsLoadingStudents] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [listHeight, setListHeight] = useState('auto');
    const [token, setToken] = useState(localStorage.getItem('token'));
    const listRef = useRef(null);
    const navigate = useNavigate();

    const isTokenExpired = (token) => {
        try {
            const decoded = jwtDecode(token);
            const currentTime = Math.floor(Date.now() / 1000);
            return decoded.exp < currentTime;
        } catch (error) {
            console.error('Error decoding token:', error);
            return true;
        }
    };

    // Funktion für Logout
    const handleLogout = () => {
        localStorage.removeItem('token'); // Entferne das Token aus dem LocalStorage
        navigate('/'); // Navigiere zur Login-Seite
    };

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (!storedToken || isTokenExpired(storedToken)) {
            navigate('/loginFirst'); // Falls kein Token oder Token abgelaufen, zum Login umleiten
        } else {
            setToken(storedToken); // Token setzen, falls gültig
        }
    }, [navigate]);

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const data = await getStudents();
                setStudents(data);
            } catch (error) {
                console.error('Error fetching students:', error);
            } finally {
                setIsLoadingStudents(false);
            }
        };
        if (token) {
            fetchStudents();
        }
    }, [token]);

    useEffect(() => {
        if (listRef.current) {
            setListHeight(listRef.current.offsetHeight);
        }
    }, [isLoadingStudents]);

    const handleSelectStudent = async (student) => {
        setSelectedStudent(student);
        setLessons([]);
        setTrainingStatus([]);
        setIsLoadingData(true);

        try {
            const studentLessons = await getLessons(student.id);
            setLessons(studentLessons);

            const studentTrainingStatus = await getTrainingStatus(student.id);
            setTrainingStatus(studentTrainingStatus);
        } catch (error) {
            console.error('Error fetching lessons or training status:', error);
        } finally {
            setIsLoadingData(false);
        }
    };

    const handleAddStudent = async (newStudent) => {
        const addedStudent = await addStudent(newStudent);
        setStudents([...students, addedStudent]);
    };

    const handleAddLesson = async (newLesson) => {
        try {
            const addedLesson = await addLesson({ ...newLesson, studentId: selectedStudent.id });
            setLessons((prevLessons) => [...prevLessons, addedLesson]);
        } catch (error) {
            console.error('Error adding lesson:', error);
        }
    };

    const handleUpdateLesson = (updatedLesson) => {
        setLessons((prevLessons) =>
            prevLessons.map((lesson) =>
                lesson.fahrstunden_id === updatedLesson.fahrstunden_id ? updatedLesson : lesson
            )
        );
    };

    const handleDeleteLesson = (deletedLessonId) => {
        setLessons((prevLessons) =>
            prevLessons.filter((lesson) => lesson.fahrstunden_id !== deletedLessonId)
        );
    };

    const handleDeleteStudent = async (studentId) => {
        try {
            await deleteStudent(studentId);
            setStudents(students.filter(student => student.id !== studentId));
            if (selectedStudent && selectedStudent.id === studentId) {
                setSelectedStudent(null);
                setLessons([]);
                setTrainingStatus([]);
            }
        } catch (error) {
            console.error('Error deleting student:', error);
        }
    };

    return (
        <Container style={{ maxWidth: "100%" }}>
            <UserDashboardHeader mode={mode} setMode={setMode} onLogout={handleLogout} /> {/* timeLeft als Prop übergeben */}
            <Grid container spacing={2} style={{ maxWidth: "100%", overflowX: "auto", marginTop: '16px' }}>
                <Grid item xs={12} md={collapsed ? 1 : 4}>
                    <Paper
                        style={{
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'calc(100vh - 150px)',
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton onClick={() => setCollapsed(!collapsed)} color="primary">
                                {collapsed ? <ChevronRight /> : <ChevronLeft />}
                            </IconButton>
                            {!collapsed && (
                                <Typography variant="h6" style={{ marginLeft: '8px', marginBottom: '0px' }}>
                                    Fahrschüler Liste
                                </Typography>
                            )}
                        </div>
                        <div ref={listRef} style={{ display: collapsed ? 'none' : 'block', flexGrow: 1 }}>
                            {isLoadingStudents ? (
                                <CircularProgress style={{ margin: '50px auto', marginBottom: listHeight }} />
                            ) : (
                                <StudentList
                                    students={students}
                                    setStudents={setStudents}
                                    onSelect={handleSelectStudent}
                                    onDeleteSuccess={handleDeleteStudent}
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={collapsed ? 11 : 8}>
                    <Paper style={{ padding: '16px', height: 'calc(100vh - 150px)', overflowY: 'auto', overflowX: 'hidden' }}>
                        {isLoadingData ? (
                            <CircularProgress />
                        ) : (
                            <>
                                {mode === 'view' && selectedStudent && (
                                    <LessonList
                                        lessons={lessons}
                                        student={selectedStudent}
                                        onUpdateLesson={handleUpdateLesson}
                                        onDeleteLesson={handleDeleteLesson}
                                        onAddLesson={handleAddLesson}
                                    />
                                )}
                                {mode === 'addStudent' && (
                                    <AddStudent onAdd={handleAddStudent} />
                                )}
                                {mode === 'trainingStatus' && selectedStudent && (
                                    <TrainingStatus trainingStatus={trainingStatus} student={selectedStudent} />
                                )}
                                {mode === 'calendar' && (
                                    <MyCalendar />
                                )}
                            </>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default UserDashboard;

