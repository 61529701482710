import React, { useState } from 'react';
import { TextField, Button, Grid, Typography } from '@mui/material';

const AddStudent = ({ onAdd }) => {
    const [name, setName] = useState('');
    const [vorname, setVorname] = useState('');
    const [geburtsdatum, setGeburtsdatum] = useState('');
    const [telefonnummer, setTelefonnummer] = useState('');
    const [email, setEmail] = useState('');
    const [strasse, setStrasse] = useState('');
    const [ort, setOrt] = useState('');
    const [plz, setPlz] = useState('');
    const [bild, setBild] = useState(null);
    const [preview, setPreview] = useState(null);

    const handleSubmit = () => {
        const newStudent = {
            name,
            vorname,
            geburtsdatum,
            telefonnummer,
            email,
            strasse,
            ort,
            plz,
            bild
        };
        onAdd(newStudent);
        setName('');
        setVorname('');
        setGeburtsdatum('');
        setTelefonnummer('');
        setEmail('');
        setStrasse('');
        setOrt('');
        setPlz('');
        setBild(null);
        setPreview(null);
    };

    const handleBildChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setBild(reader.result);
            setPreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    return (
        <>
            <h3>Neuen Fahrschüler hinzufügen</h3>
            <Grid container spacing={2} style={{ maxWidth: "100%" }}>
                <Grid item xs={6}>
                    <TextField
                        label="Vorname"
                        variant="outlined"
                        fullWidth
                        value={vorname}
                        onChange={e => setVorname(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Nachname"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Geburtsdatum"
                        variant="outlined"
                        fullWidth
                        type="date"
                        InputLabelProps={{shrink: true}}
                        value={geburtsdatum}
                        onChange={e => setGeburtsdatum(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Telefonnummer"
                        variant="outlined"
                        fullWidth
                        value={telefonnummer}
                        onChange={e => setTelefonnummer(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="E-Mail"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Straße"
                        variant="outlined"
                        fullWidth
                        value={strasse}
                        onChange={e => setStrasse(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Ort"
                        variant="outlined"
                        fullWidth
                        value={ort}
                        onChange={e => setOrt(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="PLZ"
                        variant="outlined"
                        fullWidth
                        value={plz}
                        onChange={e => setPlz(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                    >
                        Bild von LFA hochladen
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={handleBildChange}
                        />
                    </Button>
                </Grid>
                {preview && (
                    <Grid item xs={12}
                          style={{maxWidth: "100%", overflowX: "auto", textAlign: 'center', marginTop: '16px'}}>
                        <Typography variant="subtitle1">Bildvorschau:</Typography>
                        <img
                            src={preview}
                            alt="Bildvorschau"
                            style={{width: '100%', maxWidth: '300px', marginTop: '8px'}}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{ maxWidth: "100%", overflowX: "auto", marginTop: '16px' }}
                        fullWidth
                    >
                        Hinzufügen
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default AddStudent;