import React, { useEffect } from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            navigate('/');  // Leitet zum Login um, wenn kein Token vorhanden ist
        } else {
            navigate('/loginFirst');  // Token setzen, falls vorhanden
        }
    }, [navigate]);

    const handleLoginRedirect = () => {
        navigate('/'); // Weiterleitung zur Login-Seite
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh" // Passt sich der Höhe des aktuellen Geräts an
            width="100vw"     // Passt sich der Breite des aktuellen Geräts an
            textAlign="center" // Zentriert den Text
            p={2} // Fügt etwas Innenabstand hinzu
        >
            <Typography variant="h4" color="error" gutterBottom>
                Fehler: Kein gültiger Token gefunden
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
                Bitte loggen Sie sich erneut ein, um Zugriff auf diese Seite zu erhalten.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleLoginRedirect}>
                Zurück zum Login
            </Button>
        </Box>
    );
};

export default ErrorPage;