import React, { useContext } from 'react';
import { Tabs, Tab, Switch, FormControlLabel, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { ThemeContext } from '../../ThemeContext';
import { styled } from '@mui/system';

const CustomSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase': {
        color: '#2196F3',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#2196F3',
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#BBDEFB',
    },
    '& .MuiSwitch-track.Mui-checked': {
        backgroundColor: '#2196F3',
    },
}));

const UserDashboardHeader = ({ mode, setMode, onLogout }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);

    return (
        <div style={{ maxWidth: "100%", overflowX: "auto", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Tabs
                value={mode}
                onChange={(e, newValue) => setMode(newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant={isMobile ? 'scrollable' : 'standard'}
                scrollButtons="auto"
                centered={!isMobile}
            >
                <Tab label="Fahrschüler hinzufügen" value="addStudent" />
                <Tab label="Fahrstunden" value="view" />
                <Tab label="Ausbildungsstand" value="trainingStatus" />
                <Tab label="Kalender" value="calendar" />
            </Tabs>

            <div style={{ display: 'flex', alignItems: 'center' }}>

                <FormControlLabel
                    control={
                        <CustomSwitch
                            checked={darkMode}
                            onChange={toggleDarkMode}
                            icon={
                                <WbSunnyIcon
                                    style={{
                                        maxWidth: "100%",
                                        transform: 'translateY(-3px)',
                                    }}
                                />
                            }
                            checkedIcon={
                                <DarkModeIcon
                                    style={{
                                        maxWidth: "100%",
                                        transform: 'translateY(-3px)'
                                    }}
                                />
                            }
                        />
                    }
                    label=""
                />

                <Button
                    onClick={onLogout}
                    color="secondary"
                    variant="contained"
                    style={{ marginLeft: '16px' }}
                >
                    Logout
                </Button>
            </div>
        </div>
    );
};

export default UserDashboardHeader;